import React, { useState } from "react";
import { Container, Accordion, Col, Row, Form, Card, InputGroup } from "react-bootstrap";

import {FaSearch} from "react-icons/fa";

import MenuAuto from "../../images/icon-filtro/filtro-auto.png";
import MenuAutoA from "../../images/icon-filtro/filtro-auto-azul.png";
import MenuSuvs from "../../images/icon-filtro//filtro-suv.png";
import MenuSuvsA from "../../images/icon-filtro/filtro-suv-azul.png";
import MenuPickup from "../../images/icon-filtro/filtro-pickup.png";
import MenuPickupA from "../../images/icon-filtro/filtro-pickup-azul.png";
import MenuDeportivos from "../../images/icon-filtro/filtro-deportivo.png";
import MenuDeportivosA from "../../images/icon-filtro/filtro-deportivo-azul.png";

import * as stylesIndex from "../../styles/styles-landingindex.module.css";

export default function FiltroSeminuevos({onFilter, marcas, seleccionTipos}){

    const [ acordeon, setAcordeon ] = useState(null);

    const typesImages = [
        { image: MenuAuto, imageChosen: MenuAutoA },
        { image: MenuSuvs, imageChosen: MenuSuvsA },
        { image: MenuPickup, imageChosen: MenuPickupA },
        { image: MenuDeportivos, imageChosen: MenuDeportivosA },
    ];

    const types = ["Autos", "Suvs", "PickUps y Van", "Deportivos y Eléctricos"];
    const typesId = ["autos", "suvs", "pickupsyvan", "deportivosyelectricos"];

    const mappingTipos = types.map((obj, i) => {
        //console.log("Tengo lo siguinete; ", seleccionTipos);
        return(
            <Col key={i} style={{width: '45%'}} 
                className={seleccionTipos[i] === obj ? stylesIndex.botonFiltronuevoCheck : stylesIndex.botonFiltronuevo}
            >
                <div className="form-group form-check" style={{position: 'absolute'}}>
                    <input type="checkbox" className="form-check-input rounded" id={`exampleCheck${i}`} style={{height: 17, width: 17}}/>
                </div>
                    <label id={`filtroSemis-${typesId[i]}`} className="form-check-label" for={`exampleCheck${i}`} style={{position: 'relative'}} onClick={() => onFilter(obj, "Tipos")}>
                        <img
                            src={typesImages[i].image}
                            alt="checkbox"
                            className="pt-2"
                            style={{width: '100%'}}
                            loading="lazy"
                            onMouseOver={(e) => (e.currentTarget.src = typesImages[i].imageChosen)}
                            onMouseOut={(e) => (e.currentTarget.src = typesImages[i].image)}
                        />
                        <div className={`text-center`} style={{ lineHeight: "15px" }}>
                            <span className={"text-filtro-seleccion"}>
                                {obj}
                            </span>
                        </div>
                    </label>
            </Col>
        )
    });

    const mappingMarcas = marcas?.map((obj, i) => {
        return(
            <Col key={i} style={{ width: '45%'}} className="p-1 m-1 boton-filtronuevo">
                <div className="form-group form-check" >
                    <input type="checkbox" className="form-check-input rounded" id={`exampleCheckMarcas${i}`} style={{height: 17, width: 17}}/>
                </div>
                <label className="form-check-label" for={`exampleCheckMarcas${i}`} onClick={() => onFilter(obj.name, "Marcas")}>
                    <img
                        src={`https://d3s2hob8w3xwk8.cloudfront.net/makes/${obj.name.toLowerCase()}.png`}
                        alt="checkbox"
                        style={{width: '100%'}}
                        loading="lazy"
                    />
                    <div className={`text-center`} style={{ lineHeight: "15px" }}>
                        <span className={"text-filtro-seleccion"}>
                            {obj.name}
                        </span>
                    </div>
                </label>
            </Col>
        )
    });

    return(
        <>
            <div className="d-none d-md-block">
                <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Buscar..."
                    aria-describedby="basic-addon1"
                    onChange={(e) => onFilter(e.target.value, "Buscador")}
                    />
                        <InputGroup.Text id="basic-addon1"><FaSearch size={15}/></InputGroup.Text>
                </InputGroup>
            </div>
            <Card style={{backgroundColor: '#343a40'}}>
                <Card.Body>
                    <Container>
                        

                        <div className={"texto-filtro-titulo"}>
                            <h5 style={{ fontSize: 15}}>Tipo</h5>
                        </div>
                        <Row lg={2} md={2} sm={2} xs={2} style={{display: 'flex', justifyContent:'center'}}>
                            {mappingTipos}
                        </Row>
                        <hr />
                        <div className="d-none d-md-block">
                            <div className={"texto-filtro-titulo"}>
                                <h5 style={{ fontSize: 15}}>Marcas</h5>
                            </div>
                            <Row lg={2} md={2} sm={2} xs={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                {mappingMarcas}
                            </Row>
                        </div>
                        <div className="d-md-none d-sm-block">
                            <div className={"texto-filtro-titulo"}>
                                <h5 style={{ fontSize: 15}}>Marcas</h5>
                            </div>
                            <Accordion activeKey={acordeon} flush>

                                <div style={{display: 'flex', justifyContent: 'flex-end'}} className={"texto-filtro-titulo"} eventKey="0" onClick={() => setAcordeon(acordeon === "0" ? null : "0")}>
                                    <h5 style={{ fontSize: 15}}>Filtro</h5>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-funnel-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                                        <path fillRule="evenodd" d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                    </svg>
                                </div>

                                <Accordion.Collapse eventKey="0" >
                                    <Row lg={2} md={2} sm={2} xs={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {mappingMarcas}
                                    </Row>
                                </Accordion.Collapse>
                            </Accordion>
                        </div>
                    </Container>
                </Card.Body>
            </Card>
        </>
    )

}